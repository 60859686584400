import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    alpha,
    AppBar,
    Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    createTheme,
    IconButton,
    InputBase,
    Paper,
    styled,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    ThemeProvider,
    Toolbar
} from '@material-ui/core';
import MUIDataTable, { TableBody, TableHead } from 'mui-datatables';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from 'axios';

export interface ITask {
    id: Number;
    contract_id: Number;
    auftraggeberName: String;
    art: String;
    monat: String;
    anzahl_monteure: Number;
    einsaetze: Number;
    import_json: Boolean;
    facilities: { bezeichnung: String; lage_im_standort: String, standort: String }[];
}

export interface ITaskApi {
    id: Number;
    anzahl_monteure: Number;
    erledigt: Number;
    monat: String;
    jahr: String;
    contract: {
        id: Number;
        import_json: Number;
        vertragsart: 'wartung' | 'service' | 'montage';
        customer: {
            id: Number;
            name: String;
        };
        facilities: [];
    };
    appointments: [];
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}));

function TasksTable($token) {
    const MIN_LENGTH = 3;

    const [done, setDone] = useState(false);

    const [searchText, setSearchText] = useState('');
    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: 'contract_id',
            label: 'Vertrags-ID',
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: 'import_json',
            label: 'Import-JSON',
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: 'auftraggeberName',
            label: 'Kunde',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'art',
            label: 'Art',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const style = {
                        'text-transform': 'capitalize'
                    }
                    return (<div style={style}>{value}</div>)
                }
            }
        },
        {
            name: 'monat',
            label: 'geplanter Monat',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'anzahl_monteure',
            label: 'Monteure',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'einsaetze',
            label: 'Einsätze',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'actions',
            label: 'Aktionen',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const id = tableMeta.rowData[0];
                    const contractId = tableMeta.rowData[1];
                    const hasImportJson = tableMeta.rowData[2];
                    return (
                        <>
                            <a
                                href={`/tasks/${id}`}
                                className="btn btn-outline-primary"
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                            <a
                                href={`/tasks/${id}/appointments/create`}
                                className="btn btn-outline-primary"
                            >
                                <i className="fas fa-calendar-plus"></i>
                            </a>
                            <a
                                href={`/tasks/${id}/edit`}
                                className="btn btn-outline-primary"
                            >
                                <i className="fas fa-edit"></i>
                            </a>
                            {hasImportJson && (
                                <a
                                    href={`/contracts/${contractId}/importjson`}
                                    className="btn btn-outline-primary"
                                >
                                    <i className="fas fa-info-circle"></i>
                                </a>
                            )}
                            <button type="button" className="btn btn-outline-primary"
                                onClick={() => {
                                    if (confirm(`Auftrag als ${done ? 'nicht ' : ''}erledigt markieren?`)) {
                                        axios
                                            .post(`/api/task/${id}/${done ? 'undo' : 'done'}`)
                                            .then(() => {
                                                setReload(true);
                                            })
                                            .catch(error => console.log(error));
                                    }
                                }}
                            >
                                <i className={`fas ${done ? 'fa-undo' : 'fa-check'}`}></i>
                            </button>
                        </>
                    );
                }
            }
        }
    ];
    let initialState: ITask[] = [];
    const [rows, setRows] = useState(initialState);

    const [reload, setReload] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (reload) {
            setRows(initialState);
            setLoading(true);
            const timeOutId = setTimeout(() => {
                axios
                    .get('/api/tasks/search/' + (done ? 'done' : 'new') + '/' + searchText + '/', { withCredentials: true })
                    .then((response) => {
                        const result: ITaskApi[] = response.data;
                        const list: ITask[] = result.map((elem) => {
                            return {
                                id: elem.id,
                                import_json: (elem.contract.import_json === 1),
                                contract_id: elem.contract.id,
                                auftraggeberName:
                                    elem.contract.customer.name,
                                anzahl_monteure: elem.anzahl_monteure,
                                art: elem.contract.vertragsart,
                                einsaetze: elem.appointments.length,
                                facilities: elem.contract.facilities,
                                monat: elem.monat && elem.jahr && `${elem.monat}/${elem.jahr}`
                            };
                        });
                        setRows(list);
                    })
                    .catch((error) => console.error(error))
                    .finally(() => {
                        setLoading(false);
                        setReload(false);
                    });
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [searchText, done, reload]);

    const options = {
        download: false,
        filter: false,
        search: false,
        viewColumns: false,
        selectableRows: undefined,
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const task = rows.find((row) => row.id === rowData[0]);
            return (
                <>
                    {task?.facilities.map(elem => {
                        return (
                            <TableRow>
                                <TableCell colSpan={1}></TableCell>
                                <TableCell colSpan={1}>
                                    Standort: {`${elem.building.address.strasse}, ${elem.building.address.plz} ${elem.building.address.ort}`}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    Anlage: {elem.bezeichnung}
                                </TableCell>
                                <TableCell colSpan={3}>
                                    Lage im Standort: {elem.lage_im_standort}
                                </TableCell>
                            </TableRow>);
                    })}
                </>
            );
        }
    };
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <ThemeProvider
                    theme={createTheme({
                        palette: {
                            primary: {
                                main: '#2e83d8'
                            },
                            secondary: {
                                main: '#FFFFFF'
                            }
                        }
                    })}
                >
                    <AppBar position="static">
                        <Toolbar>
                            <Search>
                                <StyledInputBase
                                    placeholder="Suchen"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => {
                                        setSearchText(e.currentTarget.value);
                                        setReload(true);
                                    }}
                                />
                            </Search>
                            <ToggleButtonGroup
                                value={done}
                                exclusive
                                onChange={(e, v) => {
                                    setDone(v);
                                    setReload(true);
                                }}
                                aria-label="erledigt"
                            >
                                <ToggleButton
                                    value={false}
                                    aria-label="to be done"
                                >
                                    Offen
                                </ToggleButton>
                                <ToggleButton value={true} aria-label="done">
                                    Erledigt
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Box sx={{ flexGrow: 1 }} />
                        </Toolbar>
                    </AppBar>
                </ThemeProvider>
            </Box>
            {loading && <CircularProgress />}
            {!loading && (
                <MUIDataTable
                    title={'Aufträge'}
                    data={rows}
                    columns={columns}
                    options={options}
                />
            )}
        </>
    );
}

export default TasksTable;

if (document.getElementById('tasks-table')) {
    ReactDOM.render(<TasksTable />, document.getElementById('tasks-table'));
}
