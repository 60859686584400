import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import {
    alpha,
    AppBar,
    Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    createTheme,
    IconButton,
    InputBase,
    styled,
    TextField,
    ThemeProvider,
    Toolbar
} from '@material-ui/core';
import MUIDataTable, { TableHead } from 'mui-datatables';
import axios from 'axios';

export interface IFacilityAPI {
    id: Number;
    bezeichnung: String;
    lage_im_standort: String;
    building: {
        bezeichnung: String;
        address: {
            strasse: String;
            plz: String;
            ort: String;
        }
    }
}

export interface IFacility {
    id: Number;
    standort: String;
    anlage: String;
    lage_im_standort: String;
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}));

export function selectFacility(facilityId, contractId): Promise<void> {
    return new Promise((resolve, reject) => {
        axios
            .put(`/api/contracts/${contractId}/facilities/${facilityId}`)
            .then(() => resolve())
            .catch(error => reject(error));
    });
}

interface FacilitiesForMaintenanceTableProps {
    contractId: number;
}

function FacilitiesForMaintenanceTable<FacilitiesForMaintenanceTableProps>({ contractId }) {
    const MIN_LENGTH = 3;
    const [searchText, setSearchText] = useState('');
    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: 'standort',
            label: 'Standort',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'anlage',
            label: 'Anlage',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'lage_im_standort',
            label: 'Lage im Standort',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'actions',
            label: 'Aktionen',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value && (<button onClick={() => {
                        selectFacility(value, contractId).then(() => {
                            setForceReload(true);
                        });
                    }}>Hinzufügen</button>));
                },
            }
        }
    ];
    let initialState: IFacility[] = [];

    const [rows, setRows] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [forceReload, setForceReload] = useState(false);
    useEffect(() => {
        if (forceReload) {
            setForceReload(false);
        }
        setRows(initialState);
        setLoading(true);
        const timeOutId = setTimeout(() => {
            axios.get('/api/facilities/search/' + encodeURIComponent(searchText))
                .then((response) => {
                    axios.get(`/api/contracts/${contractId}/facilities`).then(facilitiesResponse => {
                        const result: IFacilityAPI[] = response.data;
                        const newState: IFacility[] = result.map(elem => {
                            let standort = `${elem.building.address.strasse}, ${elem.building.address.plz} ${elem.building.address.ort}`;
                            if (elem.building.bezeichnung) {
                                standort = `${standort} (${elem.building.bezeichnung})`;
                            }

                            const alreadyInFacilities: { id: number }[] = facilitiesResponse.data;
                            const isAlreadyIn = alreadyInFacilities.some(facilitesInElem => {
                                return facilitesInElem.id === elem.id
                            });
                            return {
                                id: elem.id,
                                anlage: elem.bezeichnung,
                                lage_im_standort: elem.lage_im_standort,
                                standort: standort,
                                actions: !isAlreadyIn && elem.id,
                            };
                        });
                        setRows(newState);
                    });
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    setLoading(false);
                });
        }, 200);
        return () => clearTimeout(timeOutId);
    }, [searchText, forceReload]);

    const options = {
        download: false,
        filter: false,
        search: false,
        viewColumns: false,
        selectableRows: undefined
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <ThemeProvider
                    theme={createTheme({
                        palette: {
                            primary: {
                                main: '#2e83d8'
                            },
                            secondary: {
                                main: '#FFFFFF'
                            }
                        }
                    })}
                >
                    <AppBar position="static">
                        <Toolbar>
                            <Search>
                                <StyledInputBase
                                    placeholder="Suchen"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) =>
                                        setSearchText(e.currentTarget.value)
                                    }
                                />
                            </Search>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button
                                href="/customers/create"
                                variant="contained"
                            >
                                Neu
                            </Button>
                        </Toolbar>
                    </AppBar>
                </ThemeProvider>
            </Box>
            {loading && (
                <CircularProgress />
            )}
            {!loading && (
                <MUIDataTable
                    title={'Anlage auswählen'}
                    data={rows}
                    columns={columns}
                    options={options}
                />
            )}
        </>
    );
}

export default FacilitiesForMaintenanceTable;

if (document.getElementById('facilities-for-maintenance-table')) {
    ReactDOM.render(
        <FacilitiesForMaintenanceTable contractId={document.getElementById('facilities-for-maintenance-table')?.getAttribute('contractId')} />,
        document.getElementById('facilities-for-maintenance-table')
    );
}
