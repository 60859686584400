/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */
 global.$ = global.jQuery = require('jquery');
require('./bootstrap');

import 'jquery-ui/ui/widgets/datepicker.js';
/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/Tables/customers.tsx');
require('./components/Tables/tasks.tsx');
require('./components/Tables/maintenancecontracts.tsx');
require('./components/Tables/facilitiesForMaintenance.tsx');
require('./components/Tables/buildings.tsx');
require('axios');
require('./map.js');