import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    alpha,
    AppBar,
    Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    createTheme,
    IconButton,
    InputBase,
    Paper,
    styled,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    ThemeProvider,
    Toolbar
} from '@material-ui/core';
import MUIDataTable, { TableBody, TableHead } from 'mui-datatables';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from 'axios';

export interface IMaintenanceContract {
    id: Number;
    contract_id: Number;
    auftraggeberName: String;
    ansprechpartner: String;
    wartungsvertragNummer: String;
    import_json: Boolean;
    suchschluessel: String;
    bemerkung: String;
    facilities: { bezeichnung: String; lage_im_standort: String, standort: String }[];
}

export interface IMaintenanceContractAPI {
    id: Number;
    bemerkung: String;
    anzahl_monteure: Number;
    auftragsnummer_abs: String;
    suchschluessel: String;
    contract: {
        id: Number;
        import_json: Number;
        vertragsart: 'wartung' | 'service' | 'montage';
        customer: {
            id: Number;
            name: String;
        };
        facilities: [];
        ansprechpartner_auftraggeber: {
            id: Number;
            firstname: String;
            lastname: String;
            phone: String;
            mobile: String;
            email: String;
        };
    };
    appointments: [];
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}));

function MaintenancecontractsTable() {
    const MIN_LENGTH = 3;

    const [done, setDone] = useState(false);

    const [searchText, setSearchText] = useState('');
    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: 'contract_id',
            label: 'Vertrags-ID',
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: 'auftraggeberName',
            label: 'Kunde',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'ansprechpartner',
            label: 'Ansprechpartner',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'wartungsvertragNummer',
            label: 'Wartungsvertragsnummer',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'suchschluessel',
            label: 'Suchschlüssel',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'bemerkung',
            label: 'Bemerkung',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'actions',
            label: 'Aktionen',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const id = tableMeta.rowData[0];
                    return (
                        <>
                            <a
                                href={`/maintenancecontracts/${id}`}
                                className="btn btn-outline-primary"
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                        </>
                    );
                }
            }
        }
    ];
    let initialState: IMaintenanceContract[] = [];
    const [rows, setRows] = useState(initialState);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setRows(initialState);
        setLoading(true);
        const timeOutId = setTimeout(() => {
            axios
                .get(
                    '/api/maintenancecontracts/search/' + searchText + '/'
                )
                .then((response) => {
                    const result: IMaintenanceContractAPI[] = response.data;
                    const list: IMaintenanceContract[] = result.map((elem) => {
                        return {
                            id: elem.id,
                            import_json: (elem.contract.import_json === 1),
                            contract_id: elem.contract.id,
                            auftraggeberName:
                                elem.contract.customer.name,
                            ansprechpartner: elem.contract.ansprechpartner_auftraggeber && `${elem.contract.ansprechpartner_auftraggeber.firstname} ${elem.contract.ansprechpartner_auftraggeber.lastname}`,
                            anzahl_monteure: elem.anzahl_monteure,
                            art: elem.contract.vertragsart,
                            facilities: elem.contract.facilities,
                            wartungsvertragNummer: elem.auftragsnummer_abs,
                            suchschluessel: elem.suchschluessel,
                            bemerkung: elem.bemerkung,
                        };
                    });
                    setRows(list);
                })
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchText, done]);

    const options = {
        download: false,
        filter: false,
        search: false,
        viewColumns: false,
        selectableRows: undefined,
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const task = rows.find((row) => row.id === rowData[0]);
            return (
                <>
                    {task?.facilities.map(elem => {
                        return (
                            <TableRow>
                                <TableCell colSpan={1}></TableCell>
                                <TableCell colSpan={1}>
                                    Standort: {`${elem.building.address.strasse}, ${elem.building.address.plz} ${elem.building.address.ort}`}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    Anlage: {elem.bezeichnung}
                                </TableCell>
                                <TableCell colSpan={3}>
                                    Lage im Standort: {elem.lage_im_standort}
                                </TableCell>
                            </TableRow>);
                    })}
                </>
            );
        }
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <ThemeProvider
                    theme={createTheme({
                        palette: {
                            primary: {
                                main: '#2e83d8'
                            },
                            secondary: {
                                main: '#FFFFFF'
                            }
                        }
                    })}
                >
                    <AppBar position="static">
                        <Toolbar>
                            <Search>
                                <StyledInputBase
                                    placeholder="Suchen"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) =>
                                        setSearchText(e.currentTarget.value)
                                    }
                                />
                            </Search>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button
                                href="/batch"
                                variant="contained"
                            >
                                Massendruck
                            </Button>
                        </Toolbar>
                    </AppBar>
                </ThemeProvider>
            </Box>
            {loading && <CircularProgress />}
            {!loading && (
                <MUIDataTable
                    title={'Wartungsverträge'}
                    data={rows}
                    columns={columns}
                    options={options}
                />
            )}
        </>
    );
}

export default MaintenancecontractsTable;

if (document.getElementById('maintenancecontracts-table')) {
    ReactDOM.render(<MaintenancecontractsTable />, document.getElementById('maintenancecontracts-table'));
}
