//Kundendienst, Wartungsverträge, Montagen
let auftraege = [];
// Heimadressen der Monteure:
let users = [];
// Händleradressen:
let stores = [];

let markerList = [];
//Google Maps Karte
let map;


//Optionen für die Karte:
var mapStyle = [{
    featureType: "poi",
    elementType: "labels",
    stylers: [{
        visibility: "off"
    }]
}];

var markers = [];
let infowindow;

window.addEventListener('load', function () {
    $("#map").height(700);
    $("input.map-checkbox").on("change", function () {
        drawMarker();
    });
    $("#custom-termin-select").on("change", function () {
        $("#custom-termin").prop("checked", true);
        drawMarker();
    });
    $("#custom-monteur-select").on("change", function () {
        $("#custom-monteur").prop("checked", true);
        drawMarker();
    });
})

window.initMap = () => {
    map = new google.maps.Map(document.getElementById('map'), {
        center: {
            lat: 52.5656361,
            lng: 13.3279217
        },
        zoom: 8,
        styles: mapStyle
    });
    // Add tasks:
    loadTasks();
}

function loadTasks() {
    axios
        .get("/api/tasks")
        .then(response => {
            const data = response.data;
            auftraege = Object.values(data.tasks);
            users = Object.values(data.users);
            stores = Object.values(data.stores);
            fillUi();
            drawMarker();
        })
        .catch(error => {
            console.error(error);
        });
}

function fillUi() {
    // Count Service:
    $("#countService").html(auftraege.filter(a => a.contract.vertragsart === 'service').length);
    // Count Wartung:
    $("#countWartung").html(auftraege.filter(a => a.contract.vertragsart === 'wartung').length);
    // Count Montage:
    $("#countMontage").html(auftraege.filter(a => a.contract.vertragsart === 'montage').length);
    // Count Doku:
    // Dates:
    let dates = [];
    auftraege.forEach(a => {
        dates = [...dates, ...a.appointments.map(app => app.date)];
    });
    // Remove past dates:
    dates = dates.filter(date => {
        const givenDate = new Date(date);
        givenDate.setHours(0, 0, 0, 0);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return givenDate >= today;
    });

    // Remove duplicates:
    dates = dates.filter((item, pos) => dates.indexOf(item) == pos);

    // Sort ascending
    dates.sort((a, b) => new Date(a) - new Date(b));

    // Format and append:
    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    dates.forEach(date => {
        $("#custom-termin-select").append(new Option(new Date(date).toLocaleDateString('de-DE', dateOptions), date));
    });
    // Dudes:
}

function drawMarker() {
    // Remove all Markers
    markerList.forEach(elem => elem.setMap(null));

    let filterResult = auftraege;
    // Apply Filters:
    // Filter by Type:
    filterResult = filterResult.filter(auftrag => {
        if ($("#typ-K").prop("checked") && auftrag.contract.vertragsart === 'service') {
            return true;
        }
        if ($("#typ-W").prop("checked") && auftrag.contract.vertragsart === 'wartung') {
            return true;
        }
        if ($("#typ-M").prop("checked") && auftrag.contract.vertragsart === 'montage') {
            return true;
        }
        return false;
    });

    // Filter by Date:
    filterResult = filterResult.filter(auftrag => {
        switch ($("input[name=terminRadio]:checked").val()) {
            case 'all':
                return true;
            case 'custom':
                const selectedDate = $("#custom-termin-select option:selected").val();
                return auftrag.appointments.filter(app => app.date === selectedDate).length > 0;
            case 'none':
                return (auftrag.appointments.length == 0);
            default: break;
        }
    });

    // Create new markers
    markerList = filterResult.map(auftrag => {
        var icon = 'https://maps.google.com/mapfiles/ms/icons/blue.png';
        switch (auftrag.contract.vertragsart) {
            case 'service':
                icon = 'https://maps.google.com/mapfiles/ms/icons/red.png';
                break;
            case 'montage':
                icon = 'https://maps.google.com/mapfiles/ms/icons/yellow.png';
                break;
            case 'wartung':
                icon = 'https://maps.google.com/mapfiles/ms/icons/green.png';
                break;
            default: break;
        }
        var image = {
            url: icon,
            // This marker is 20 pixels wide by 32 pixels high.
            size: new google.maps.Size(32, 32),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            labelOrigin: new google.maps.Point(16, 12),
        };
        var pos = {
            lat: parseFloat(auftrag.contract.facilities[0].building.address.lat),
            lng: parseFloat(auftrag.contract.facilities[0].building.address.lng)
        };
        console.log('Pos', pos);
        const marker = new google.maps.Marker({
            position: pos,
            map: map,
            icon: image,
            title: auftrag.contract.customer.name,
            strasse: auftrag.contract.facilities[0].building.address.strasse,
            plz: auftrag.contract.facilities[0].building.address.plz,
            ort: auftrag.contract.facilities[0].building.address.ort,
            taskId: auftrag.id,
            label: `${auftrag.anzahl_monteure}`,
        });
        infowindow = new google.maps.InfoWindow({
            content: this.label
        });
        marker.addListener('click', function () {
            var infowindowContent = "<div id='iw-container' style='display: inline-block'>";
            infowindowContent += "<div class='iw-title'>";
            infowindowContent += this.title;
            infowindowContent += "</div>";
            infowindowContent += "<hr />";
            //Adresse:
            infowindowContent += "<label>Adresse:</label>";
            infowindowContent += "<p>" + this.strasse + "<br />";
            infowindowContent += this.plz + " " + this.ort + "</p>";
            infowindowContent += "<hr />";

            infowindowContent += "<label>Link:</label>";
            infowindowContent += "<a href='/tasks/" + this.taskId + "'>Auftrag</a>";
            infowindowContent += "</div>";


            infowindow.setContent(infowindowContent);
            infowindow.open(map, this);
        });
        return marker;
    });

    if ($("#address-stores").prop("checked")) {
        const storeMarkers = stores.filter(store => store.address).map(store => {
            var icon = 'https://maps.google.com/mapfiles/ms/icons/blue.png';
            var image = {
                url: icon,
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(32, 32),
                // The origin for this image is (0, 0).
                origin: new google.maps.Point(0, 0),
                labelOrigin: new google.maps.Point(16, 12),
            };
            var pos = {
                lat: parseFloat(store.address.lat),
                lng: parseFloat(store.address.lng)
            };
            const marker = new google.maps.Marker({
                position: pos,
                map: map,
                icon: image,
                name: store.name,
                strasse: store.address.strasse,
                plz: store.address.plz,
                ort: store.address.ort,
            });
            infowindow = new google.maps.InfoWindow({
                content: this.label
            });
            marker.addListener('click', function () {
                var infowindowContent = "<div id='iw-container' style='display: inline-block'>";
                infowindowContent += "<div class='iw-title'>";
                infowindowContent += `Händler: ${this.name}`;
                infowindowContent += "</div>";
                infowindowContent += "<hr />";
                //Adresse:
                infowindowContent += "<label>Adresse:</label>";
                infowindowContent += "<p>" + this.strasse + "<br />";
                infowindowContent += this.plz + " " + this.ort + "</p>";
                infowindowContent += "<hr />";
                infowindowContent += "</div>";


                infowindow.setContent(infowindowContent);
                infowindow.open(map, this);
            });
            return marker;
        });
        markerList = [...markerList, ...storeMarkers];
    }

    if ($("#address-users").prop("checked")) {
        const userMarkers = users.filter(user => user.address && user.address.lat && user.address.lng).map(user => {
            var icon = 'https://maps.google.com/mapfiles/ms/icons/homegardenbusiness.png';
            var image = {
                url: icon,
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(32, 32),
                // The origin for this image is (0, 0).
                origin: new google.maps.Point(0, 0),
                labelOrigin: new google.maps.Point(16, 12),
            };
            var pos = {
                lat: parseFloat(user.address.lat),
                lng: parseFloat(user.address.lng)
            };
            const marker = new google.maps.Marker({
                position: pos,
                map: map,
                icon: image,
                firstname: user.firstname,
                lastname: user.lastname,
                strasse: user.address.strasse,
                plz: user.address.plz,
                ort: user.address.ort,
            });
            infowindow = new google.maps.InfoWindow({
                content: this.label
            });
            marker.addListener('click', function () {
                var infowindowContent = "<div id='iw-container' style='display: inline-block'>";
                infowindowContent += "<div class='iw-title'>";
                infowindowContent += `Heimadresse: ${this.firstname} ${this.lastname}`;
                infowindowContent += "</div>";
                infowindowContent += "<hr />";
                //Adresse:
                infowindowContent += "<label>Adresse:</label>";
                infowindowContent += "<p>" + this.strasse + "<br />";
                infowindowContent += this.plz + " " + this.ort + "</p>";
                infowindowContent += "<hr />";
                infowindowContent += "</div>";


                infowindow.setContent(infowindowContent);
                infowindow.open(map, this);
            });
            return marker;
        });
        markerList = [...markerList, ...userMarkers];
    }
    // Fit map to bounds:
    if (markerList.length > 0) {
        console.log(markerList);
        var bounds = new google.maps.LatLngBounds();
        markerList.forEach(marker => bounds.extend(marker.getPosition()));
        map.fitBounds(bounds);
    }

}