import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    alpha,
    AppBar,
    Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    createTheme,
    IconButton,
    InputBase,
    styled,
    TextField,
    ThemeProvider,
    Toolbar
} from '@material-ui/core';
import MUIDataTable, { TableHead } from 'mui-datatables';
import axios from 'axios';

export interface ICustomer {
    id: Number;
    name: String;
    projektnummerHwp: String;
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}));

function CustomersTable() {
    const MIN_LENGTH = 3;
    const [searchText, setSearchText] = useState('');
    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'projektnummerHwp',
            label: 'Projektnummer HWP',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'notiz',
            label: 'Notiz',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'actions',
            label: 'Aktionen',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const id = tableMeta.rowData[0];
                    return (
                        <>
                            <a
                                href={`/customers/${id}/edit`}
                                className="btn btn-outline-primary"
                            >
                                <i className="fas fa-edit"></i>
                            </a>
                            <a
                                href={`/customers/${id}`}
                                className="btn btn-outline-primary"
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                            <a
                                href={`/customers/${id}/merge`}
                                className="btn btn-outline-primary"
                            >
                                <i className="fas fa-compress-arrows-alt"></i>
                            </a>
                        </>
                    );
                }
            }
        }
    ];
    let initialState: ICustomer[] = [];
    const [rows, setRows] = useState(initialState);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setRows(initialState);
        setLoading(true);
        const timeOutId = setTimeout(() => {
            axios.get('/api/customers/search/' + encodeURIComponent(searchText))
                .then((response) => {
                    const result: ICustomer[] = response.data;
                    setRows(result);
                })
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchText]);

    const options = {
        download: false,
        filter: false,
        search: false,
        viewColumns: false,
        selectableRows: undefined
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <ThemeProvider
                    theme={createTheme({
                        palette: {
                            primary: {
                                main: '#2e83d8'
                            },
                            secondary: {
                                main: '#FFFFFF'
                            }
                        }
                    })}
                >
                    <AppBar position="static">
                        <Toolbar>
                            <Search>
                                <StyledInputBase
                                    placeholder="Suchen"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) =>
                                        setSearchText(e.currentTarget.value)
                                    }
                                />
                            </Search>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button
                                href="/customers/create"
                                variant="contained"
                            >
                                Neu
                            </Button>
                        </Toolbar>
                    </AppBar>
                </ThemeProvider>
            </Box>
            {loading && (
                <CircularProgress />
            )}
            {!loading && (
                <MUIDataTable
                    title={'Auftraggeber'}
                    data={rows}
                    columns={columns}
                    options={options}
                />
            )}
        </>
    );
}

export default CustomersTable;

if (document.getElementById('customers-table')) {
    ReactDOM.render(
        <CustomersTable />,
        document.getElementById('customers-table')
    );
}
